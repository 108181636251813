body {
  background-color: #f7f7f7;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#root {
  padding: 10px;
}

.two-sections {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.todaysbooking{
  padding: 20px;
}

.booknow h2{
  margin: 0;
}

.home {
  display: flex;
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
}

.homepageImg{
  max-width: 100%;
}

.green {
  color: rgb(0, 93, 6);
}

.link {
  border: 1px solid rgb(0, 20, 149);
  padding: 10px;
  text-decoration: none;
  color: rgb(0, 20, 149);
  margin: 10px 0;
  display: inline-block;
}

.headings {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 22px;
  margin-bottom: 20px;
}

.form-container {
  box-sizing: border-box;
  width: 100%;
  max-width: 400px;
  margin: 0 auto 50px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: transform 0.3s ease-in-out;
}

.form-field {
  margin-bottom: 20px;
  display: none;
}

.form-field.active {
  display: block;
}

.form-field input {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  font-size: 16px;
  border: none;
  background-color: #e8f5ff;
  border-radius: 5px;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
  transition: transform 0.3s ease-in-out;
}

.form-field label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #44344f;
}

.form-field.invalid input {
  background-color: #d3fcd5;
}

.error-message {
  color: red;
  font-size: 12px;
}

button {
  background-color: #564d80;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #44344f;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}

.slide-left-enter {
  transform: translateX(100%);
}

.slide-left-enter-active {
  transform: translateX(0%);
  transition: transform 0.3s ease-in-out;
}

.slide-left-exit {
  transform: translateX(0%);
}

.slide-left-exit-active {
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
}

.slide-right-enter {
  transform: translateX(-100%);
}

.slide-right-enter-active {
  transform: translateX(0%);
  transition: transform 0.3s ease-in-out;
}

.slide-right-exit {
  transform: translateX(0%);
}

.slide-right-exit-active {
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.submitted-data {
  margin-top: 20px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
}

.submitted-data p {
  margin: 0;
}

.table-container {
  margin: 20px;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.table th {
  background-color: #f2f2f2;
}

.table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table tbody tr:hover {
  background-color: #eaf6ff;
}

.check-more{
  margin-top: 10px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.card-row {
  width: 100%;
  max-width: 520px;
}

.card {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 20px;
  background-color: #fff;
}

.card-header {
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.card-body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

@media only screen and (max-width: 800px) {
  .card-body {
    display: grid;
    gap: 10px;
    grid-template-columns: auto;
  }
}

.card-field {
  display: flex;
  flex-direction: column;
}

.card-label {
  font-weight: bold;
}

.card-value {
  margin-top: 5px;
  word-break: break-word;
}

.card-date {
  background-color: #1d5d90;
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: bold;
  color: #fff;
}

.quote {
  margin: 0;
  background: #eee;
  padding: 1em;
  border-radius: 1em;
}
.quote figcaption,
.quote blockquote {
  margin: 1em;
}